import { FormControl, FormGroup } from '@angular/forms';
import * as CustomValidators from '@shared/helpers/validators.helper';

export interface VerifyPhoneForm {
  number: FormControl<string>;
  code: FormControl<string>;
}

export class PhoneVerificationForm extends FormGroup<VerifyPhoneForm> {
  constructor() {
    super({
      number: new FormControl<string>(undefined!, {
        nonNullable: true,
        validators: [
          CustomValidators.required,
          CustomValidators.minLength(13, 'Debe ingresar un número de 10 digitos'),
        ],
      }),
      code: new FormControl<string>(undefined!, {
        nonNullable: true,
        validators: [
          CustomValidators.noSpecialCharacters,
          CustomValidators.length(6),
          CustomValidators.required,
        ],
      }),
    });
  }
}
