<tui-loader
  *rxLet="isSending$; let isSending"
  [showLoader]="isSending && !codeSent()"
  [inheritColor]="true"
  [overlay]="true">
  <div class="px-8">
    <div class="relative h-[90vh] bg-white md:h-[28rem]">
      <ng-container *ngIf="!codeSent()">
        <h1 class="mt-8 text-center text-xl font-semibold text-gray-800">
          {{ title }}
        </h1>
        <div class="my-4 flex justify-center">
          <img class="w-14" src="assets/images/valid_number/form1.svg" alt="separator" />
        </div>
        <p class="text-center text-sm text-gray-600">
          {{ subtitle }}
        </p>

        <div class="mt-4">
          <tui-input-phone-international
            [formControl]="phoneControl"
            [tuiTextfieldSize]="sizeInput"
            [tuiTextfieldLabelOutside]="true"
            [countryIsoCode]="countries[0]"
            (countryIsoCodeChange)="changeCountry($event)"
            [countries]="countries">
            <p class="text-left">Número celular</p>
          </tui-input-phone-international>

          <tui-error [formControl]="phoneControl" [error]="[] | tuiFieldError | async"></tui-error>
          <p class="text-left text-sm text-gray-400 md:text-base">Ej. 55 1234 1234</p>
        </div>

        <div class="mt-4 flex justify-center">
          <button
            tribuuButton
            theme="dark"
            class="!min-w-full md:min-w-fit"
            type="button"
            [disabled]="phoneControl.invalid"
            (click)="sendOTPCode()">
            Enviar código
          </button>
        </div>
        <p class="mt-2 text-center text-xs text-gray-600">
          Te enviaremos a tu celular vía SMS un código de validación, el cual deberás ingresar a
          continuación.
        </p>
      </ng-container>

      <section
        class="fade-in-app realtive flex h-full w-full flex-col items-center justify-center"
        *ngIf="codeSent()">
        <h1 class="text-center text-xl font-semibold text-gray-800">
          Ingresa el código que te enviamos
        </h1>
        <tui-input
          class="mt-4 w-full !uppercase"
          id="code"
          [tuiTextfieldSize]="sizeInput"
          [formControl]="codeControl"
          [tuiTextfieldLabelOutside]="true">
          <p class="text-center">- - - - - -</p>
          <input
            type="text"
            class="!text-center !uppercase"
            tuiTextfield
            maxlength="6"
            minlength="6" />
        </tui-input>
        <tui-error [formControl]="codeControl" [error]="[] | tuiFieldError | async"></tui-error>

        <p class="mt-2 w-full text-center text-xs text-gray-400">
          El tiempo de envío depende de tu línea telefónica.
        </p>

        <div class="mt-4 flex w-full justify-center">
          <button
            (click)="validCode()"
            type="button"
            tribuuButton
            theme="primary"
            [disabled]="codeControl.invalid || isSending"
            class="!min-w-full md:min-w-fit">
            {{ isSending ? 'Validando...' : 'Validar' }}
          </button>
        </div>
        <p
          class="mt-2 w-full cursor-pointer text-center text-xs font-medium text-secondary underline"
          (click)="codeSent.set(false)">
          Volver a enviar
        </p>
        <p class="absolute bottom-4 w-full text-center text-xs text-gray-400">
          Si no recibiste tu código, llámanos al 55 6943 7052
        </p>
      </section>
    </div>
  </div>
</tui-loader>
