import { Component, Inject, inject, Input, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '@core/services/ui/alert.service';
import { ProfileActions } from '@core/store/profile/profile.actions';
import { ProfileState } from '@core/store/profile/profile.state';
import {
  PhoneVerificationForm,
  VerifyPhoneForm,
} from '@modules/account/data/classes/form-verify-phone';
import { Store } from '@ngxs/store';
import * as CustomValidators from '@shared/helpers/validators.helper';
import { SharedModule } from '@shared/shared.module';
import { isMobile } from '@shared/utils';
import { tuiMarkControlAsTouchedAndValidate } from '@taiga-ui/cdk';
import { TuiDialogContext } from '@taiga-ui/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TuiInputPhoneInternationalModule } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-form-verify-phone',
  templateUrl: './form-verify-phone.component.html',
  standalone: true,
  imports: [SharedModule, TuiInputPhoneInternationalModule], // todo: remove shared module, import ony the components that are used
})
export class FormVerifyPhoneComponent {
  private readonly store = inject(Store);
  isSending$ = this.store.select(ProfileState.isProfileSending);
  private alerts = inject(AlertService);
  loading = false;
  @Input() form: FormGroup<VerifyPhoneForm> = new PhoneVerificationForm();
  @Input() title?: string = 'Número celular';
  @Input() subtitle?: string =
    'Para seguridad de tu información, es obligatorio verificar tu número telefónico.';

  readonly countries: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.MX,
    TuiCountryIsoCode.US,
    TuiCountryIsoCode.CO,
    TuiCountryIsoCode.ES,
  ];
  codeSent = signal(false);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean, void>
  ) {}

  readonly sizeInput = isMobile() ? 'm' : 'l';

  get phoneControl() {
    return this.form.controls.number;
  }

  get codeControl() {
    return this.form.controls.code;
  }
  async changeCountry(e: TuiCountryIsoCode) {
    if (e === TuiCountryIsoCode.ES) {
      this.form.controls.number.setValidators([
        CustomValidators.minLength(12, 'Debe ingresar un número de 9 digitos'),
      ]);
    }
    if (e === TuiCountryIsoCode.US) {
      this.form.controls.number.setValidators([
        CustomValidators.minLength(12, `Debe ingresar un número de 10 digitos`),
      ]);
    }
    if (e === TuiCountryIsoCode.MX || e === TuiCountryIsoCode.CO) {
      this.form.controls.number.setValidators([
        CustomValidators.minLength(13, `Debe ingresar un número de 10 digitos`),
      ]);
    }
  }

  sendOTPCode() {
    this.store.dispatch(new ProfileActions.SendSMS(this.phoneControl.value)).subscribe({
      next: () => {
        this.codeSent.set(true);
      },
    });
  }

  validCode() {
    if (this.codeControl.invalid) {
      tuiMarkControlAsTouchedAndValidate(this.form);
      return;
    }
    const { code } = this.form.getRawValue();
    if (!code) {
      this.alerts.warning('Ingresa un código de 6 digitos');
      return;
    }

    this.store.dispatch(new ProfileActions.VerifyPhone(code.toUpperCase())).subscribe({
      next: () => {
        this.alerts.success('¡Felicidades! Ya eres parte de la Tribuu.', 'Bien');
        this.context.completeWith(true);
      },
    }); 
  }
}
